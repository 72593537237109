define("discourse/plugins/MuditaTheme/discourse/pre-initializers/mudita-theme", ["exports", "ember-addons/ember-computed-decorators", "discourse/models/category", "discourse/models/topic", "discourse/lib/ajax"], function (_exports, _emberComputedDecorators, _category, _topic, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "mudita-theme",
    before: "inject-discourse-objects",
    initialize() {
      // Disable classes for mobile
      const classes = document.documentElement.classList;
      classes.add('desktop-view');
      classes.remove('mobile-view');

      // Categories list plugins
      _category.default.reopen(dt7948.p({
        last_activity(topics) {
          let lastBump = 0;
          let text = '';
          if (topics && topics.length) {
            topics.forEach(topic => {
              const timestamp = new Date(topic.bumped_at).getTime();
              if (timestamp > lastBump) lastBump = timestamp;
            });
          }
          if (lastBump > 0) {
            const diff = new Date().getTime() - lastBump;
            const daysDiff = diff / 1000 / 60 / 60 / 24;
            const date = new Date(lastBump);
            const time = `${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
            if (daysDiff < 1) {
              text = `Today, ${time}`;
            } else if (daysDiff < 2) {
              text = `a day ago`;
            } else if (daysDiff <= 31) {
              text = `${Math.floor(daysDiff)} days ago`;
            } else {
              const day = date.getDay();
              const month = date.toUTCString().split(',')[1].split(' ')[2];
              const year = date.getFullYear().toString().slice(2, 4);
              text = `${month} ${day}, '${year}`;
            }
          }
          return text;
        },
        last_post_author(id) {
          let author = {};
          if (id) {
            return (0, _ajax.ajax)(`/t/${id}.json`).then(result => {
              author = result.details.created_by;
              author.avatar_template = author.avatar_template.replace('{size}', 120);
              this.set('last_post_author', author);
            });
          }
        }
      }, [["method", "last_activity", [(0, _emberComputedDecorators.default)("topics")]], ["method", "last_post_author", [(0, _emberComputedDecorators.default)("latestTopic.id")]]]));

      // Topics list plugins
      _topic.default.reopen(dt7948.p({
        custom_featured_users(users) {
          const max = 3;
          const featuredUsers = [];
          const usersCount = users.length < max ? users.length : max;
          const left = users.length - usersCount;
          for (let i = 0; i < usersCount; i++) {
            featuredUsers.push(users[i]);
          }
          return {
            list: featuredUsers,
            left: left > 0 ? left : null
          };
        }
      }, [["method", "custom_featured_users", [(0, _emberComputedDecorators.default)("featuredUsers")]]]));
    }
  };
});